#vector_1,
#vector_2,
#vector_3,
#vector_4,
#vector_5,
#vector_6 {
  position: absolute;
  will-change: transform, opacity;
  transform: translateZ(0);
}

#vector_1 {
  width: 100vw;
  bottom: -20vh;
  right: -45vw;
  animation: vector_1 20s infinite;
  z-index: 5;
  min-width: 1000px;
}

#vector_2 {
  bottom: -25vh;
  right: 27vw;
  filter: blur(130px);
  transform: rotate(26.48deg);
  animation: vector_2 20s infinite;
  fill: rgba(255, 131, 16, 0.9);
  min-width: 700px;
}

#vector_3 {
  width: 102.5vw;
  top: -5vh;
  left: -65vw;
  filter: blur(100px);
  animation: vector_3 20s infinite;
  z-index: 4;
  min-width: 1000px;
}

#vector_4 {
  width: 42vw;
  top: -30vh;
  left: -20vw;
  filter: blur(10px);
  animation: vector_4 20s infinite;
  fill: #f7ecc8;
  min-width: 600px;
}

#vector_5 {
  width: 60.5vw;
  top: -55vh;
  right: -5vw;
  filter: blur(150px);
  animation: vector_5 20s infinite;
  z-index: 1;
  min-width: 750px;
}

#vector_6 {
  width: 31.7vw;
  top: -30vh;
  right: 28vw;
  filter: blur(80px);
  transform: rotate(-43deg);
  animation: vector_6 20s infinite;
  z-index: 2;
  min-width: 650px;
}

@keyframes vector_1 {
  0%, 100% {
    transform: translate(-23vw, 20vh) rotate(0.689deg);
  }
  33% {
    transform: translate(-10vw, -35vh);
  }
  66% {
    transform: translate(-2vw, 19vh);
  }
}

@keyframes vector_2 {
  0%, 100% {
    transform: translate(-10vw, 0vh);
    width: 42.9vw;
  }
  33% {
    transform: translate(35vw, 0vh);
    width: 47vw;
    opacity: 0.8;
  }
  66% {
    transform: translate(20vw, 50vh) rotate(-26.484deg);
    width: 75vw;
    opacity: 0.8;
  }
}

@keyframes vector_3 {
  0%, 100% {
    width: 102.5vw;
    transform: translate(0vw, 0vh) rotate(10deg);
  }
  33% {
    width: 105.5vw;
    transform: translate(30vw, -3vh) rotate(13deg);
  }
  66% {
    width: 120.5vw;
    transform: translate(15vw, -45vh) rotate(15deg);
  }
}

@keyframes vector_4 {
  0%, 100% {
    transform: translate(-6vw, 0vh) rotate(-123deg);
  }
  33% {
    transform: translate(-5vw, 20vh) rotate(-123deg);
  }
  66% {
    transform: translate(0vw, 5vh) rotate(-123deg);
  }
}

@keyframes vector_5 {
  0%, 100% {
    transform: translate(5vw, 0vh) rotate(30deg);
  }
  33% {
    transform: translate(2vw, -10vh) rotate(30deg);
  }
  66% {
    transform: translate(5vw, -15vh) rotate(30deg);
  }
}

@keyframes vector_6 {
  0%, 100% {
    transform: translate(0vw, 0vh);
    opacity: 1;
  }
  33% {
    transform: translate(20vw, 65vh) rotate(-43deg);
    opacity: 0.7;
  }
  66% {
    transform: translate(20vw, 75vh) rotate(-43deg);
    opacity: 0.7;
  }
}