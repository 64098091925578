#bookingForm {
  @apply md:w-[400px] w-[300px];
}

#bookingForm .nav-tabs {
  @apply hidden;
}

#bookingForm .form-group {
  @apply mt-0;
}

#bookingForm .form-group:nth-child(2) .input-group {
  @apply before:content-[url('/assets/images/location.png')] 
  before:h-[16px] before:w-[16px] before:object-contain
  relative before:absolute before:left-[16px];
}

#bookingForm .form-group:nth-child(3) .input-group {
  @apply before:content-[url('/assets/images/destination.png')] 
  before:h-[16px] before:w-[16px] before:object-contain
  relative before:absolute before:left-[16px];
}

#bookingForm .vdatetime {
  @apply before:content-[url('/assets/images/calendar.svg')]
  before:h-[16px] before:w-[16px] before:object-cover;
}

#bookingForm .vdatetime input {
  @apply !pr-[30px] !pl-[18px] md:!pl-[28px];
}

#bookingForm .input-group {
  @apply mt-[10px] !flex w-full !flex-row items-center 
  rounded-[7.5px] md:rounded-[10px] bg-white py-[15.5px] md:py-[20px] md:max-w-[400px];
}

#bookingForm .input-group input,
#bookingForm .vdatetime .vdatetime-input {
  @apply flex-1 bg-transparent text-black outline-none text-[12px] md:text-[16px] 
   placeholder:text-[#888888] border-none h-[19px] px-[20px];
}

#bookingForm .vdatetime {
  @apply flex w-full flex-row items-center space-x-[32px] rounded-[7.5px] md:rounded-[10px]
   bg-white px-[16px] py-[15.5px] md:py-[20px] md:max-w-[400px];
}

#bookingForm .row .col-12 .ml-1 {
  @apply hidden;
}

#bookingForm .row .col-12 button {
  font-family: "DM Sans", sans-serif;
  @apply flex flex-row justify-center items-start rounded-[7.5px] md:rounded-[10px]
   bg-white px-[32px] 
  font-bold tracking-[-3%] text-black relative outline-none md:py-[20px]
  hover:opacity-80 transition-all h-[40px] md:h-auto text-[12px] md:text-[16px] 
  text-center py-[10px];
}

/* #bookingForm .row .col-12 button::after {
  @apply absolute top-0 left-0 right-0 bottom-0 bg-white
} */

#bookingForm .vdatetime .vdatetime-popup {
  @apply !outline-none rounded-[10px] overflow-hidden !shadow-none;
}

#bookingForm .vdatetime .vdatetime-popup__header {
  @apply bg-black;
}

#bookingForm .vdatetime-calendar__month__day--selected span {
  @apply bg-black rounded-full;
}

#bookingForm .vdatetime-popup__actions__button,
#bookingForm .vdatetime-time-picker__item--selected,
.cbs-passenger-details-form .cbs-booking-summary-change-section a
{
  @apply !text-black 
}

.cbs-passenger-details-form {
  @apply !bg-black
}

.cbs-passenger-details-form .btn-primary {
  @apply !bg-black
}

.bookingConfirmBooking img.mr-2 { display: inline-block; }

/* Autocomplete */
.pac-item-query {
  font-size: 16px;
  display: block;
}
.pac-matched {}

.pac-container {
  border-radius: 0px;
  background: white;
  border: 0.1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  @apply mt-[24px] rounded-[10px] p-[5px] animate-fade-down animate-duration-300;
}

.pac-target-input {
  @apply md:!pl-[60px] !pl-[49px];
}

.pac-item {
  display: block;
  flex-direction: column;
  font-size: 12px;
  border-radius: 16px;
  border: 0.1px solid white;
  cursor: pointer;
  transition: background-color 0.1s ease;
  @apply relative pl-[60px] py-[10px] before:content-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMjkxIDIxLjcwNiAxMiAyMWwtLjcwOS43MDZ6TTEyIDIxbC43MDguNzA2YTEgMSAwIDAgMS0xLjQxNyAwbC0uMDA2LS4wMDctLjAxNy0uMDE3LS4wNjItLjA2M2E0Ny43MDggNDcuNzA4IDAgMCAxLTEuMDQtMS4xMDYgNDkuNTYyIDQ5LjU2MiAwIDAgMS0yLjQ1Ni0yLjkwOGMtLjg5Mi0xLjE1LTEuODA0LTIuNDUtMi40OTctMy43MzRDNC41MzUgMTIuNjEyIDQgMTEuMjQ4IDQgMTBjMC00LjUzOSAzLjU5Mi04IDgtOCA0LjQwOCAwIDggMy40NjEgOCA4IDAgMS4yNDgtLjUzNSAyLjYxMi0xLjIxMyAzLjg3LS42OTMgMS4yODYtMS42MDQgMi41ODUtMi40OTcgMy43MzVhNDkuNTgzIDQ5LjU4MyAwIDAgMS0zLjQ5NiA0LjAxNGwtLjA2Mi4wNjMtLjAxNy4wMTctLjAwNi4wMDZMMTIgMjF6bTAtOGEzIDMgMCAxIDAgMC02IDMgMyAwIDAgMCAwIDZ6Ii8+Cjwvc3ZnPgo=')]
  before:h-[16px] before:w-[16px] before:object-contain before:absolute 
  before:left-[16px] hover:!bg-[#BFBFBF] flex flex-col justify-center before:mb-[6px]; 
}

.pac-item-selected {
  &:before {
    filter: invert(38%) sepia(80%) saturate(600%) hue-rotate(-10deg) brightness(120%) contrast(110%);
  }
}

.pac-item:hover {
  background-color: #f1f1f1;
}

.pac-icon {
  display: none;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}



