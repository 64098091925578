#smvector_1,
#smvector_2,
#smvector_3,
#smvector_4,
#smvector_5,
#smvector_6 {
  position: absolute;
  will-change: transform, opacity;
  transform: translateZ(0);
}

#smvector_1 {
  width: 47.2vw;
  height: 98vh;
  fill: #ff981e;
  filter: blur(0px);
  animation: smvector_1 15s infinite;
  min-width: 450px;
}

@keyframes smvector_1 {
  0%, 100% {
    transform: translate(28vw, -58vh) rotate(-30deg);
  }
  33% {
    transform: translate(10vw, -61vh) rotate(-30deg);
  }
  66% {
    transform: translate(-25vw, -28vh) rotate(-30deg);
  }
}

#smvector_2 {
  width: 32.4vw;
  height: 52.2vh;
  fill: #f7ecc8;
  filter: blur(50px);
  animation: smvector_2 15s infinite;
  min-width: 400px;
}

@keyframes smvector_2 {
  0%, 100% {
    transform: translate(30vw, -30vh) rotate(-123.135deg);
  }
  33% {
    transform: translate(13vw, -30vh) rotate(-133.281deg);
  }
  66% {
    transform: translate(-15vw, 0vh) rotate(-133.281deg);
  }
}

#smvector_3 {
  width: 37.4vw;
  height: 150.5vh;
  fill: rgba(212, 50, 0, 0.9);
  filter: blur(20px);
  animation: smvector_3 15s infinite;
  min-width: 400px;
}

@keyframes smvector_3 {
  0%, 100% {
    transform: translate(13vw, -60vh);
  }
  33% {
    transform: translate(-12vw, -50vh) rotate(10deg);
  }
  66% {
    transform: translate(-10vw, 5vh) rotate(1deg);
  }
}

#smvector_4 {
  width: 26.7vw;
  height: 43vh;
  fill: #F7F2C8;
  filter: blur(10px);
  animation: smvector_4 15s infinite;
  min-width: 350px;
}

@keyframes smvector_4 {
  0%, 100% {
    transform: translate(22vw, 35vh);
  }
  33% {
    transform: translate(48vw, 45vh);
  }
  66% {
    transform: translate(48vw, 45vh);
  }
}

#smvector_5 {
  height: 120%;
  width: 70.5%;
  fill: rgba(255, 131, 16, 0.9);
  filter: blur(70px);
  animation: smvector_5 15s infinite;
  min-width: 400px;
}

@keyframes smvector_5 {
  0%, 100% {
    transform: translate(10vw, 15vh);
  }
  33% {
    transform: translate(25vw, 17vh);
  }
  66% {
    width: 70vh;
    height: 75vh;
    transform: translate(52vw, -5vh) rotate(-30deg);
  }
}

#smvector_6 {
  fill: rgba(255, 131, 16, 0.9);
  filter: blur(20px);
  animation: smvector_6 15s infinite;
  min-width: 400px;
}

@keyframes smvector_6 {
  0%, 100% {
    width: 77.8vw;
    height: 66.4vh;
    transform: translate(12vw, 35vh);
  }
  33% {
    width: 45.3vw;
    height: 97vh;
    transform: translate(48vw, 3vh);
  }
  66% {
    width: 45.3vw;
    height: 97vh;
    transform: translate(48vw, -25vh);
  }
}