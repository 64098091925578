html,
body {
  margin: 0;
  padding: 0;
}

.text-container {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 96px;
  color: white;
  opacity: 0.8;
  user-select: none;
}

:root {
  --color-bg1: rgb(108, 0, 162);
  --color-bg2: rgb(0, 17, 82);
  --color1: 212, 50, 0;
  --color2: 247, 236, 200;
  --color3: 255, 131, 16;
  --color4: 242, 79, 29;
  --color5: 247, 236, 200;
  --color6: 255, 152, 30;
  --color-interactive: 140, 100, 255;
  --circle-size: 80%;
  --blending: hard-light;
}

@keyframes g1Move {
  0%,
  100% {
    transform: translate(-45vw, 0vh) rotate(0deg);
  }
  33% {
    transform: translate(-35vw, 5vh) rotate(180deg);
  }
  66% {
    transform: translate(-35vw, -50vh) rotate(0deg);
  }
}

@keyframes g2Move {
  0%,
  100% {
    transform: translate(-15vw, -18vh) rotate(0deg);
  }
  33% {
    transform: translate(0vw, 10vh);
    width: calc(var(--circle-size) / 2);
    height: calc(var(--circle-size) / 2);
  }
  66% {
    transform: translate(-20vw, -18vh) rotate(0deg);
    width: calc(var(--circle-size) / 2);
    height: calc(var(--circle-size) / 2);
  }
}

@keyframes g3Move {
  0%,
  100% {
    transform: translate(0vw, 40vh) rotate(10deg);
  }
  33% {
    transform: translate(45vw, 30vh) rotate(13deg);
  }
  66% {
    transform: translate(0vw, 35vh) rotate(15deg);
  }
}

@keyframes g4Move {
  0%,
  100% {
    transform: translate(45vw, 50vh) rotate(123deg);
  }
  33% {
    transform: translate(45vw, 15vh) rotate(-123deg);
  }
  66% {
    transform: translate(50vw, 45vh) rotate(-123deg);
  }
}

@keyframes g5Move {
  0%,
  100% {
    transform: translate(75vw, -20vh) rotate(30deg);
  }
  33% {
    transform: translate(90vw, 30vh) rotate(30deg);
  }
  66% {
    transform: translate(95vw, 35vh) rotate(35deg);
  }
}

@keyframes g6Move {
  0%,
  100% {
    transform: translate(75vw, -40vh) rotate(30deg);
  }
  33% {
    transform: translate(90vw, -35vh) rotate(30deg);
  }
  66% {
    transform: translate(70vw, -20vh) rotate(35deg);
  }
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.gradient-bg {
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #000;
  top: 0;
  left: 0;

  svg {
    display: none;
  }

  .gradients-container {
    filter: url(#goo) blur(40px);
    width: 100%;
    height: 100%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color1), 0.8) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 1.2);
    height: calc(var(--circle-size) * 1.2);

    transform-origin: center center;
    animation: g1Move 30s ease infinite;

    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    border-radius: 16% 84% 14% 86% / 30% 59% 41% 70%;
    z-index: 100;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color2), 0.4) 0,
        rgba(var(--color2), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) / 1.5);
    height: calc(var(--circle-size) / 1.5);
    // top: calc(50% - var(--circle-size) / 2);
    // left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: g2Move 30s reverse infinite;

    opacity: 1;
    z-index: 99;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color3), 0.8) 0,
        rgba(var(--color3), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 1.5);
    height: calc(var(--circle-size) * 1.5);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: g3Move 30s linear infinite;
    z-index: 100;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color4), 0.8) 0,
        rgba(var(--color4), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: g4Move 30s ease infinite;
    z-index: 99;
    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color5), 0.5) 0,
        rgba(var(--color5), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) / 2);
    height: calc(var(--circle-size) / 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: g5Move 30s ease infinite;
    z-index: 98;
    opacity: 1;
  }

  .g6 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color6), 0.7) 0,
        rgba(var(--color6), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 0.9);
    height: calc(var(--circle-size) * 0.9);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: g6Move 30s ease infinite;
    z-index: 99;
    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color1), 0.8) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size));
    height: calc(var(--circle-size));
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}
