.cbs-vehicle-content .cbs-vehicle-content-select-btn {
  @apply bg-black
}
#bookingForm .btn-primary {
  @apply bg-black
}
#bookingForm .vdatetime .vdatetime-popup__header {
  @apply bg-black
}

.container .bookingDetails {
  @apply  min-w-[900px]
}